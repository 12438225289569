import React from 'react';

// import assets
import consumers from '../../images/brands/otm_consumers_calcon.png';
import realEstate from '../../images/brands/otm_real_estate_calcon.png';

// import components
import { BrandBody } from '../../components/BrandBase';
import BrandSelector from '../../components/BrandSelector';
import { Grid } from '../../components/Grid';
import Layout from '../../components/Layout';

const materials = [
  {
    name: 'Consumers',
    icon: consumers,
    url:
      'https://onetrusthomeloans.sharepoint.com/subsite/OneTrust%20University/OneTrust%20Marketing/Asset%20Management/Audience%20-%20Consumers/CalCon',
  },
  {
    name: 'Real Estate Agents',
    icon: realEstate,
    url:
      'https://onetrusthomeloans.sharepoint.com/subsite/OneTrust%20University/OneTrust%20Marketing/Asset%20Management/Audience%20-%20Real%20Estate%20Agents/CalCon',
  },
];

const CalCon = () => {
  const drawMaterials = materials => {
    return materials.map(material => {
      const { icon, name, url } = material;

      return (
        <li key={name}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <img src={icon} alt={name} />
            <span>{name}</span>
          </a>
        </li>
      );
    });
  };

  return (
    <Layout>
      <Grid>
        <BrandSelector active="calcon" />
        <BrandBody>
          <h2>CalCon Mutual Mortgage</h2>
          <p>Please choose your intended audience.</p>
          <ul>{drawMaterials(materials)}</ul>
        </BrandBody>
      </Grid>
    </Layout>
  );
};

export default CalCon;
